var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-main',{staticClass:"solution-tertiary"},[_c('section',{staticClass:"exposition bottom no_padding"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"exposition_body",attrs:{"cols":"12","sm":"5"}},[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.$t('solution.tertiary.introduction.title'))}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('solution.tertiary.introduction.body'))}}),_c('div',[_c('v-btn',{staticClass:"button-main mg_r_15",attrs:{"outlined":"","ripple":false,"href":"https://forms.homepad.com/homePad/form/Webform/formperma/vmb04z0Z7MUwsUPALVRIvhMiJqbVaKdQ48uVfQTnVss","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("global.actions.demo"))+" ")])],1),_c('div',[_c('v-btn',{staticClass:"button-main subscribe mg_r_15",attrs:{"outlined":"","ripple":false,"href":"https://store.homepad.com","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("global.actions.subscribe"))+" ")])],1)]),_c('v-col',{staticClass:"exposition_illustration",attrs:{"cols":"12","sm":"6","offset-sm":"1"}},[_c('img',{attrs:{"src":require("@/assets/images/solution/tertiary_introduction.svg"),"alt":"Landlord using homePad","width":"100%"}})])],1)],1)],1),_c('section',{staticClass:"activities"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"activities_title",attrs:{"cols":"12","sm":"6","offset-sm":"3"}},[_c('span',[_vm._v(_vm._s(_vm.$t("solution.tertiary.description.subtitle")))]),_c('h2',[_vm._v(_vm._s(_vm.$t("solution.tertiary.description.title")))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('solution.tertiary.description.body'))}})]),_c('v-col',{staticClass:"activities_logo",attrs:{"cols":"6","sm":"4","md":"2","offset":"3","offset-sm":"4","offset-md":"5"}},[_c('img',{attrs:{"src":require("@/assets/images/common/homePad_pack_logo.svg"),"alt":"homePad one more resolve logo","width":"100%"}})]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"row_centered"},_vm._l((_vm.$t(
                'solution.tertiary.description.cards'
              )),function(card,index){return _c('v-col',{key:index,attrs:{"cols":"8","sm":"4","md":"4","lg":"2"}},[_c('div',{staticClass:"activities_card"},[_c('div',{staticClass:"activities_card_step"},[_vm._v(_vm._s(index + 1))]),_c('img',{attrs:{"src":_vm.importIcon(card.image),"alt":"Icon representing an homePad functionality","width":"40%"}}),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({ ratio: 1.5 }),expression:"{ ratio: 1.5 }"}],domProps:{"innerHTML":_vm._s(card.text)}})])])}),1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('b',[_vm._v(_vm._s(_vm.$t("solution.tertiary.description.info")))])])],1)],1)],1),_c('section',{staticClass:"exposition"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"exposition_body",attrs:{"cols":"12","sm":"12","md":"5"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("solution.tertiary.inspections.title")))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('solution.tertiary.inspections.body'))}}),_c('v-btn',{staticClass:"button-secondary",attrs:{"outlined":"","ripple":false,"to":{ name: 'inspection' }}},[_vm._v(" "+_vm._s(_vm.$t("global.actions.more"))+" ")])],1),_c('v-col',{staticClass:"exposition_illustration",attrs:{"cols":"12","sm":"12","md":"6","offset-md":"1"}},[_c('img',{attrs:{"src":_vm.importImages(
                'solution/agents_state_' + this.$i18n.locale + '.svg'
              ),"alt":"Illustration of working with homePad","width":"100%"}})])],1)],1)],1),_c('section',{staticClass:"exposition"},[_c('v-container',[_c('v-row',[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"exposition_illustration",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('img',{attrs:{"src":_vm.importImages(
                'solution/agents_deductions_' + this.$i18n.locale + '.svg'
              ),"alt":"Illustration of landlord and tenant using homePad","width":"100%"}})]):_vm._e(),_c('v-col',{staticClass:"exposition_body",attrs:{"cols":"12","sm":"12","md":"5","offset-md":"1"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("solution.tertiary.deductions.title")))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('solution.tertiary.deductions.body'))}}),_c('v-btn',{staticClass:"button-secondary",attrs:{"outlined":"","ripple":false,"to":{ name: 'deductions' }}},[_vm._v(" "+_vm._s(_vm.$t("global.actions.more"))+" ")])],1),(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"exposition_illustration",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('img',{attrs:{"src":_vm.importImages(
                'solution/agents_deductions_' + this.$i18n.locale + '.svg'
              ),"alt":"Illustration of landlord and tenant using homePad","width":"100%"}})]):_vm._e()],1)],1)],1),_c('section',{staticClass:"exposition reporting"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"exposition_body",attrs:{"cols":"12","sm":"12","md":"5"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("solution.tertiary.reporting.title")))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('solution.tertiary.reporting.body'))}}),_c('v-btn',{staticClass:"button-secondary",attrs:{"outlined":"","ripple":false,"to":{ name: 'reporting' }}},[_vm._v(" "+_vm._s(_vm.$t("global.actions.more"))+" ")])],1),_c('v-col',{staticClass:"exposition_illustration",attrs:{"cols":"12","sm":"12","md":"6","offset-md":"1"}},[_c('img',{attrs:{"src":_vm.importImages(
                'solution/agents_faults_' + this.$i18n.locale + '.svg'
              ),"alt":"Illustration of working with homePad","width":"100%"}})])],1)],1)],1),_c('section',{staticClass:"demo-button"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"button-main",attrs:{"outlined":"","ripple":false,"href":"https://forms.homepad.com/homePad/form/Webform/formperma/vmb04z0Z7MUwsUPALVRIvhMiJqbVaKdQ48uVfQTnVss","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("global.actions.demo"))+" ")]),_c('div',[_c('v-btn',{staticClass:"button-main",attrs:{"outlined":"","ripple":false,"href":"https://store.homepad.com","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("global.actions.subscribe"))+" ")])],1)],1)],1)],1)],1),_c('section',{staticClass:"testimonies"},[_c('v-container',[_c('AppCarousel',{attrs:{"numElem":_vm.$t('solution.tertiary.testimonies').length,"elemPerPage":3}},_vm._l((this.$i18n.getLocaleMessage(
            _vm.clientCountry
          ).solution.tertiary.testimonies),function(testimonyIndex,index){return _c('div',{key:index,attrs:{"slot":'slide-item-' + index},slot:'slide-item-' + index},[_c('AppTestimonialCard',{attrs:{"index":testimonyIndex},on:{"navigateToTestimonial":function($event){_vm.setSelectedTestemonialIndexes(
                this.$i18n.getLocaleMessage(_vm.clientCountry).solution.tertiary
                  .testimonies
              )}}})],1)}),0)],1)],1),_c('section',{staticClass:"story"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"story_title",attrs:{"cols":"12","sm":"6","offset-sm":"3"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("solution.tertiary.follow.title")))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('solution.tertiary.follow.body'))}})]),_c('v-col',{staticClass:"story_logo",attrs:{"cols":"6","sm":"4","md":"2","offset":"3","offset-sm":"4","offset-md":"5"}},[_c('img',{attrs:{"src":require("@/assets/images/common/homePad_resolve_logo.svg"),"alt":"homePad one logo","width":"100%"}})]),_c('v-col',{staticClass:"story_items",attrs:{"cols":"12"}},[_c('AppStory',{attrs:{"images":[
              'solution/agents_story_card1.svg',
              'solution/agents_story_card2.svg',
              'solution/agents_story_card3.svg'
            ],"content":[
              _vm.$t('solution.tertiary.follow.stories')[0],
              _vm.$t('solution.tertiary.follow.stories')[1],
              _vm.$t('solution.tertiary.follow.stories')[2]
            ],"shadows":[
              _vm.$vuetify.breakpoint.mdAndUp ? 'bottom-left' : 'bottom-right',
              'top-right',
              _vm.$vuetify.breakpoint.mdAndUp ? 'bottom-right' : 'bottom-left'
            ]}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"button-main",attrs:{"outlined":"","ripple":false,"href":"https://forms.homepad.com/homePad/form/Webform/formperma/vmb04z0Z7MUwsUPALVRIvhMiJqbVaKdQ48uVfQTnVss","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("global.actions.demo"))+" ")]),_c('div',[_c('v-btn',{staticClass:"button-main",attrs:{"outlined":"","ripple":false,"href":"https://store.homepad.com","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("global.actions.subscribe"))+" ")])],1)],1)],1)],1)],1),_c('section',{staticClass:"more",class:{ mobile: !_vm.$vuetify.breakpoint.mdAndUp }},[_c('v-container',[_c('div',{staticClass:"more_wrapper"},[_c('v-row',[_c('v-col',{staticClass:"more_body",attrs:{"cols":"10","sm":"8","md":"6","offset":"1","offset-sm":"2","offset-md":"3"}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-trink-leplus.svg"),"alt":"homePad one logo","width":"20%"}}),_c('h2',[_vm._v(_vm._s(_vm.$t("solution.tertiary.more.title")))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('solution.tertiary.more.text'))}})])],1),_c('v-btn',{staticClass:"button-secondary-white",attrs:{"outlined":"","ripple":false,"to":{ name: 'inspection' }}},[_vm._v(" "+_vm._s(_vm.$t("global.actions.more"))+" ")])],1)])],1),_c('section',{staticClass:"offers"},[_c('h2',[_vm._v(_vm._s(_vm.$t("solution.tertiary.offers")))]),_c('v-container',[_c('v-row',_vm._l((_vm.$t('offers')),function(offer,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"4"}},[_c('AppOfferCard',{attrs:{"image":offer.image,"content":offer.content,"shadow":offer.shadow}},[_c('template',{slot:"offer-card_links"},[_c('v-btn',{staticClass:"button-main no_margin mg_t_20",attrs:{"outlined":"","ripple":false,"to":{ name: offer.link_name }}},[_vm._v(" "+_vm._s(_vm.$t(offer.link_text))+" ")])],1)],2)],1)}),1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }