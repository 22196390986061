
import Vue from "vue";
import LocaleSelect from "@/components/LocaleSelect.vue";
import AppAccordion from "@/components/AppAccordion.vue";
import AppDropdown from "@/components/AppDropdown.vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "AppNav",
  components: {
    LocaleSelect,
    AppAccordion,
    AppDropdown
  },
  data() {
    return {
      staticMenuUrls: ["recruitment", "partners", "testimonial", "404"],
      isDrawerOpen: false,
      loginMenu: {
        label: "navigation.login.label",
        submenu: [
          {
            label: "HP 24",
            description: "HP 24 description",
            href: `https://login.homepad.com?locale=`
          },
          { label: "HP vieux", description: "HP vieux description" }
        ]
      },
      drawerMenus: [
        {
          label: "navigation.products.label",
          submenu: [
            {
              label: "navigation.products.childs.enter.label",
              description: "navigation.products.childs.enter.description",
              to: "entrance"
            },
            {
              label: "navigation.products.childs.occupation.label",
              description: "navigation.products.childs.occupation.description",
              to: "occupation"
            },
            {
              label: "navigation.products.childs.exit.label",
              description: "navigation.products.childs.exit.description",
              to: "exit"
            },
            {
              label: "navigation.products.childs.concierge.label",
              description: "navigation.products.childs.concierge.description",
              to: "concierge"
            },
            {
              label: "navigation.products.childs.one.label",
              description: "navigation.products.childs.one.description",
              highlight: true,
              to: "one"
            },
            {
              label: "navigation.products.childs.resolve.label",
              description: "navigation.products.childs.resolve.description",
              highlight: true,
              to: "resolve"
            }
          ]
        },
        {
          label: "navigation.solutions.label",
          submenu: [
            {
              label: "navigation.solutions.childs.agents.label",
              description: "navigation.solutions.childs.agents.description",
              to: "agents"
            },
            {
              label: "navigation.solutions.childs.landlords.label",
              description: "navigation.solutions.childs.landlords.description",
              to: "landlords"
            },
            {
              label: "navigation.solutions.childs.managers.label",
              description: "navigation.solutions.childs.managers.description",
              to: "managers"
            },
            {
              label: "navigation.solutions.childs.residences.label",
              description: "navigation.solutions.childs.residences.description",
              to: "residences"
            },
            {
              label: "navigation.solutions.childs.tertiary.label",
              description: "navigation.solutions.childs.tertiary.description",
              to: "tertiary"
            },
            {
              label: "navigation.solutions.childs.seasonal.label",
              description: "navigation.solutions.childs.seasonal.description",
              to: "seasonal"
            },
            {
              label: "navigation.solutions.childs.subcontractors.label",
              description:
                "navigation.solutions.childs.subcontractors.description",
              to: "subcontractors"
            },
            {
              label: "navigation.solutions.childs.bailiffs.label",
              description: "navigation.solutions.childs.bailiffs.description",
              to: "bailiffs"
            }
          ]
        },
        {
          label: "navigation.offers.label",
          to: "offers"
        },
        {
          label: "navigation.blog.label",
          href: "https://blog.homepad.com/"
        },
        {
          label: "navigation.about.label",
          submenu: [
            {
              label: "navigation.about.childs.who_are_we.label",
              to: "who_are_we"
            },
            {
              label: "navigation.about.childs.recruitment.label",
              to: "recruitment"
            },
            {
              label: "navigation.about.childs.testimonials.label",
              to: "testimonials"
            },
            {
              label: "navigation.about.childs.partners.label",
              to: "partners"
            }
          ]
        },
        {
          label: "navigation.contact.label",
          submenu: [
            {
              label: "global.actions.demo",
              href:
                "https://forms.homepad.com/homePad/form/Webform/formperma/vmb04z0Z7MUwsUPALVRIvhMiJqbVaKdQ48uVfQTnVss"
            }
          ]
        }
      ],
      previousScrollPosition: 0
    };
  },
  beforeMount() {
    if (this.clientCountry !== "fr") {
      const productSubMenu = this.drawerMenus[0].submenu as Array<any>;
      productSubMenu.splice(3, 1);
    }
  },
  methods: {
    toggleDrawer() {
      this.isDrawerOpen = !this.isDrawerOpen;
    },
    handleScroll(event) {
      if (!this.staticMenuUrls.includes(this.$route.name as string)) {
        const element = this.$refs.appNav as HTMLElement;
        const navHeight = element.offsetHeight;
        if (window.scrollY > navHeight) {
          element.classList.add("fixed");
        } else {
          element.classList.remove("fixed");
        }
        if (
          window.scrollY < this.previousScrollPosition &&
          this.previousScrollPosition > 0
        ) {
          element.classList.add("active");
        } else {
          element.classList.remove("active");
        }
        this.previousScrollPosition = window.scrollY;
      }
    }
  },
  computed: {
    ...mapState(["clientCountry", "clientLanguage"])
  },
  watch: {
    $route: function(route) {
      const element = this.$refs.appNav as HTMLElement;
      element.classList.remove("fixed");
      element.classList.remove("active");
    }
  },
  created() {
    document.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll);
  }
});
